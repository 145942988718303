import React from 'react'
import Layout from '../components/_global/layouts/Layout'
import sadUnicornImage from '../../static/img/checkout/szomoru-unikornis.png'
import {FunctionComponent} from 'react'
import withLocation from '../helpers/withLocation'

type Props = {
  search: {
    gameKey: string
  }
}

const ErrorPage = ({search}: Props): FunctionComponent => {

  const mailUrl = `mailto:info@mokajandek.hu?subject=Szeretnék noszalgiázni!&body=Üdv, szeretnék nosztalgiázni. Ez a játékazonosítóm: ${search.gameKey}.`

  return (
    <Layout>
      <main data-test-id="404" className="flex-grow mx-auto flex flex-col justify-center text-center px-4">
        <img className="mb-4 w-40 mx-auto" src={sadUnicornImage} alt="szomorú unikornis" data-test-id="game-error"/>
        <h1 className="font-title text-2xl mb-4">Jaj ne!</h1>
        <div className="mb-4">A játékhoz való hozzáférés lejárt.</div>
        <div>
          Ha szeretnél nosztalgiázni, újra aktiváljuk a játékodat,<br />
          csak írj az <a href={mailUrl} className="text-aqua underline">info@mokajandek.hu</a> email címre <br />
          és tüntesd fel a játékazonosítódat: <b>{search.gameKey}</b>
        </div>
      </main>
    </Layout>
  )
}

export default withLocation(ErrorPage)